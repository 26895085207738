import React, { Component } from 'react';
import './Login.css';
import logo from './tl_logo.jpg';
import AuthService from '../services/AuthService';
import ServiceData from '../services/service_data';

class Login extends Component {

    constructor(props){
        super(props);
        this.state = {
            latitude: 0,
            longitude: 0,
            location:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.Auth = new AuthService(ServiceData.BASE_URL);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.position()
        if(this.Auth.loggedIn()) {
            this.props.history.replace('/');
        }
    }
    render() {
        return (
            <div className="center">
                <div className="card">
                    <h1>Login</h1>
                    <form onSubmit={ (e) => this.handleFormSubmit(e) } method="POST">
                        <input
                            className="form-item"
                            placeholder="Username goes here..."
                            name="username"
                            type="text"
                            onChange={this.handleChange}
                        />
                        <input
                            className="form-item"
                            placeholder="Password goes here..."
                            name="password"
                            type="password"
                            onChange={this.handleChange}
                        />
                        <input
                            className="form-submit"
                            value="SUBMIT"
                            type="submit"
                        />
                    </form>
                </div>
                <div style={{color:'white'}}>{this.state.location}</div>
            </div>
        );
    }

    handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }
    position = async () => {
        await navigator.geolocation.getCurrentPosition(
          position => this.setState({ 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude
          },()=>{
            fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.state.latitude+','+this.state.longitude+'&sensor=true&key=AIzaSyA79SV0Ml0MSFVkzyDCG4OOMhPSHOU-DWQ')
            .then(response => response.json())
            .then(data => {
             // setWeather(data);
              console.log(data);
              this.setState({location:data["results"][0]["formatted_address"]})
            })
            .catch(error => console.log(error));
          }), 
          err => console.log(err)
        );
        console.log(this.state.latitude)
       
      }
    handleFormSubmit(e){
        e.preventDefault();

        if(!this.state || !this.state.username || !this.state.password) {
            alert("Username/Password is invalid");
        } else {
            this.Auth.login(this.state.username,this.state.password)
            .then(res =>{
               this.props.history.replace('/');
            })
            .catch(err =>{
                alert(err);
            })
        }
    }
}

export default Login;